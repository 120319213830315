.meet-container {
    width: 100%;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 0.25rem;
    border: 1px solid #87929d;
}

.add-meeting {
    position: fixed;
    right: 5%;
    bottom: 5%;
}

.add-agenda {
    right: 1%;
    bottom: 1%;
    position: absolute;
}

.meeting-buttons {
    right: 5%;
    bottom: 5%;
    position: absolute;
}

.date-picker {
    margin-bottom: 5px;
    width: 180px;
    font-size: 16px;
}

.time-picker {
    margin-bottom: 5px;

    width: 180px;
    font-size: 16px;
}

.board-name {
    font-weight: bold;
    margin-bottom: -5px;
}

.participant-image {
    margin-right: 5px;
    filter: grayscale(100%);
}
.participant-attending {
    filter: grayscale(0%);
    border: 2px solid green;
}

.gray-image {
    filter: grayscale(100%);
}

.participant-active:hover {
    filter: none;
}

.agenda-container {
    text-align: center;
    vertical-align: middle;
    min-height: 400px;
    width: 100%;
}

.agenda-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.flexbox {
    display: flex;
    justify-content: space-around;
    width: 100%;
    overflow: hidden;
}

.board {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    width: 100%;
    height: 100%;
}

.board-chosen-container {
    display: flex;
    flex-direction: column;
    flex-grow: 100;
    min-height: 100px;
    height: 100%;
    margin: 10px;
}

.agenda {
    display: block;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
}
.chosen-agenda {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    background-color: white;
}

.agenda-text {
    display: flex;
    padding-left: .4em;
    width: 100%;
    align-self: center;
}

.agenda-priority {
    margin-left: 10px;
    align-self: center;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
}

.create-meeting-header {
    margin: 10px;
}

.create-meeting-body {
    flex: 1 1 auto;
    @media (min-width: 1200px) {
        height: 100%;
    }
}

.create-meeting-border-bottom {
    border-bottom: 1px solid #cacaca;
}

.create-meeting-participant-body {
    margin: 10px;
    min-height: 51px;
}

.agendas-body {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
}

.create-meeting-sidebar {
    height: 100%;
}

.create-meeting-participant-sidebar {
    display: flex;
}

.create-meeting-next-step {
    position: absolute;
    margin-top: 30px;
    margin-bottom: 15px;
    margin-right: 15px;
    bottom: 0;
    right: 0;
}
.create-meeting-cancel-edit {
    position: absolute;
    margin-bottom: 15px;
    margin-left: 15px;
    bottom: 0;
    left: 0;
}

.dragging-participant {
    display: none;
}

.sidebar-participant-container {
    margin: 4px;
}

.agenda-sidebar-board {
    min-height: 48px;
}

.styrearbeid-dialog {
    width: 500px;
    min-height: 150px;

    @media (max-width: 620px) {
        width: 300px;
    }

    @media (max-width: 420px) {
        width: 250px;
    }
    @media (max-width: 360px) {
        width: 200px;
    }
}

.item-dropzone {
    border: dashed 1px #cacaca;
    min-width: 125px;
    min-height: 25px;
    z-index: 10;
    @media (max-width: 600px) {
        min-width: 90px;
    }
}

.item-dropzone-inactive {
    min-width: 125px;
    min-height: 25px;
    @media (max-width: 600px) {
        min-width: 90px;
    }
}

.form-label-root {
    margin: 0;
}
.form-label {
    margin-bottom: 0;
}

.meeting-container {
    min-height: 720px;
}

.selected-item {
    //border: 2px solid black;
    background-color: #e6e6e6;
}

.search-meeting-container {
    margin-top: 5px;
    max-height: 400px;
    overflow: auto;
    overflow-x: hidden;
}

.search-row :hover {
    background: gainsboro;
}
