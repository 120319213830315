@import 'app/common/variables';
@import 'bootstrap/functions';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';
.cursor-pointer {
    cursor: pointer;
}

/* position: 'fixed', bottom: 0, right: 0, margin: '2rem', padding: 0, borderRadius: '50%', width: '2.5rem', height: '2.5rem', zIndex: 10 */
.round-button {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 2rem;
    padding: 0;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 10;
}

.pull-right {
    float: right !important;
}
.pull-left {
    float: left !important;
}

.html5buttonsL {
    margin-right: 10px;
}

.easypie-chart {
    > span {
        margin-top: -12.5px;
        height: 15px;
        font-size: 0.75rem;
    }
}

.fc-plus-button,
.fc-add_event-button {
    color: white;
    background-color: $success;

    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;

    &:hover {
        background-color: $success-dark;
    }
}

.fc-h-event .fc-event-time {
    overflow: unset !important;
}

.fc {
    .event-cancelled {
        .fc-list-event-dot {
            border-color: $danger;
        }
    }
}

:root {
    --fc-event-bg-color: #{$primary};
    --fc-event-border-color: #{$primary};
}

.mt-timeline {
    @media (min-width: 850px) {
        margin-bottom: 65.5px !important;
    }
}

.white-space-pre {
    white-space: pre;
}

.sidebar-nav > li > a,
.sidebar-nav > li > .nav-item {
    color: black;
}

.padded-row {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    border-top: rgba(125, 132, 143, 0.11) 1px solid;
}

.fake-button {
    cursor: pointer;
}

.rstm-toggle-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.row-button {
    transition: 0.3s;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.15);
    }
}
.chevron-anim {
    transition: transform 350ms ease;
    -moz-transition: -moz-transform 350ms ease;
    -ms-transition: -ms-transform 350ms ease;
    -o-transition: -o-transform 350ms ease;
    -webkit-transition: -webkit-transform 350ms ease;
    &-up {
        transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }
}
.gap-1\/2 {
    gap: 0.5rem;
}

.gap-1 {
    gap: 1rem;
}

@include media-breakpoint-down(sm) {
    .topnavbar .navbar-header .brand-logo {
        display: none;
    }

    .topnavbar .navbar-header .brand-logo-collapsed {
        display: block;
    }
}

.swal-text {
    text-align: center;
}
