.noteset-group-stemme {
    margin: 4px;
    padding: 8px;
    border: 1px solid darkgrey;
    min-height: 100px;
    min-width: 200px;
    text-align: center;
}

.stemme-border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 5px;
    margin-left: -30px;
    padding-left: 15px;
    margin-right: -30px;
    padding-right: 15px;
    padding-bottom: 10px;
}


.hide {
    display: none;
}

.noteset-note {
    min-width: 50%;
}

.noteset-note:hover .hide {
  display: block;
}

.noteset-stemme-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    min-height: 56px;
}