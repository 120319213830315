// The dropdown wrapper (`<div>`)
.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;

    // Generate the caret automatically
    @include caret();
}

// The dropdown menu
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $zindex-dropdown;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    min-width: $dropdown-min-width;
    padding: $dropdown-padding-y 0;
    margin: $dropdown-spacer 0 0; // override default ul
    @include font-size($dropdown-font-size);
    color: $dropdown-color;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;
    background-color: $dropdown-bg;
    background-clip: padding-box;
    border: $dropdown-border-width solid $dropdown-border-color;
    @include border-radius($dropdown-border-radius);
    @include box-shadow($dropdown-box-shadow);
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .dropdown-menu#{$infix}-left {
            right: auto;
            left: 0;
        }

        .dropdown-menu#{$infix}-right {
            right: 0;
            left: auto;
        }
    }
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
    .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: $dropdown-spacer;
    }

    .dropdown-toggle {
        @include caret(up);
    }
}

.dropright {
    .dropdown-menu {
        top: 0;
        right: auto;
        left: 100%;
        margin-top: 0;
        margin-left: $dropdown-spacer;
    }

    .dropdown-toggle {
        @include caret(right);
        &::after {
            vertical-align: 0;
        }
    }
}

.dropleft {
    .dropdown-menu {
        top: 0;
        right: 100%;
        left: auto;
        margin-top: 0;
        margin-right: $dropdown-spacer;
    }

    .dropdown-toggle {
        @include caret(left);
        &::before {
            vertical-align: 0;
        }
    }
}

// When enabled Popper.js, reset basic dropdown position
// stylelint-disable-next-line no-duplicate-selectors
.dropdown-menu {
    &[x-placement^='top'],
    &[x-placement^='right'],
    &[x-placement^='bottom'],
    &[x-placement^='left'] {
        right: auto;
        bottom: auto;
    }
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
    @include nav-divider($dropdown-divider-bg, $dropdown-divider-margin-y, true);
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
    display: block;
    width: 100%; // For `<button>`s
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    clear: both;
    font-weight: $font-weight-normal;
    color: $dropdown-link-color;
    text-align: inherit; // For `<button>`s
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    background-color: transparent; // For `<button>`s
    border: 0; // For `<button>`s

    // Prevent dropdown overflow if there's no padding
    // See https://github.com/twbs/bootstrap/pull/27703
    @if $dropdown-padding-y == 0 {
        &:first-child {
            @include border-top-radius($dropdown-inner-border-radius);
        }

        &:last-child {
            @include border-bottom-radius($dropdown-inner-border-radius);
        }
    }

    @include hover-focus() {
        color: $dropdown-link-hover-color;
        text-decoration: none;
        @include gradient-bg($dropdown-link-hover-bg);
    }

    &.active,
    &:active {
        color: $dropdown-link-active-color;
        text-decoration: none;
        @include gradient-bg($dropdown-link-active-bg);
    }

    &.disabled,
    &:disabled {
        color: $dropdown-link-disabled-color;
        pointer-events: none;
        background-color: transparent;
        // Remove CSS gradients if they're enabled
        @if $enable-gradients {
            background-image: none;
        }
    }
}

.dropdown-menu.show {
    display: block;
}

// Dropdown section headers
.dropdown-header {
    display: block;
    padding: $dropdown-padding-y $dropdown-item-padding-x;
    margin-bottom: 0; // for use with heading elements
    @include font-size($font-size-sm);
    color: $dropdown-header-color;
    white-space: nowrap; // as with > li > a
}

// Dropdown text
.dropdown-item-text {
    display: block;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    color: $dropdown-link-color;
}

.react-tel-input {
    .form-control {
        font-size: 16px;
        background: #ffffff;
        border: 1px solid #cacaca;
        border-radius: 5px;
        width: 300px;
        outline: none;
        padding: 18.5px 14px 18.5px 60px;
        transition: box-shadow ease 0.25s, border-color ease 0.25s;
        color: #495057;
        &:focus {
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            &.invalid-number {
                box-shadow: 0 0 0 0.2rem rgba(222, 0, 0, 0.25);
            }
        }
        &.invalid-number {
            border: 1px solid #f44336;
        }
    }
    .flag-dropdown {
        position: absolute;
        top: 0;
        bottom: 0;
        padding: 0;
        border-radius: 3px 0 0 3px;
        &:hover,
        &:focus {
            cursor: pointer;
        }
        &.open {
            z-index: 2;
        }
    }
    input[disabled] {
        & + .flag-dropdown {
            &:hover {
                cursor: default;
                .selected-flag {
                    background-color: transparent;
                }
            }
        }
    }
    .selected-flag {
        outline: none;
        position: relative;
        width: 52px;
        height: 100%;
        padding: 0 0 0 11px;
        border-radius: 3px 0 0 3px;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            bottom: 2px;
            left: 0;
            width: 100%;
            border-radius: 4px 2px 2px 4px;
            border: 1px solid transparent;
            transition: box-shadow ease 0.25s, border-color ease 0.25s;
        }
        &:focus,
        &.open {
            &:before {
                border-color: #80bdff;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
        }
        .flag {
            position: absolute;
            top: 50%;
            margin-top: -12px;
        }
        .arrow {
            position: relative;
            top: 50%;
            margin-top: -1px;
            left: 29px;
            width: 0;
            height: 0;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-top: 4px solid #555;
            &.up {
                border-top: none;
                border-bottom: 4px solid #555;
            }
        }
    }
    .country-list {
        outline: none;
        z-index: 1;
        list-style: none;
        position: absolute;
        padding: 0;
        margin: 10px 0 10px -1px;
        box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
        background-color: white;
        width: 300px;
        max-height: 220px;
        overflow-y: scroll;
        border-radius: 7px;
        .flag {
            display: inline-block;
            position: absolute;
            left: 13px;
            top: 8px;
        }
        .divider {
            padding-bottom: 5px;
            margin-bottom: 5px;
            border-bottom: 1px solid #ccc;
        }
        .country {
            position: relative;
            padding: 12px 9px 13px 46px;
            .dial-code {
                color: #6b6b6b;
            }
            &:hover {
                background-color: #f1f1f1;
            }
            &.highlight {
                background-color: #f1f1f1;
            }
        }
        .flag {
            margin-right: 7px;
            margin-top: 2px;
        }
        .country-name {
            margin-right: 6px;
        }
        .search {
            z-index: 2;
            position: sticky;
            top: 0;
            background-color: #fff;
            padding: 10px 0 6px 10px;
        }
        .search-emoji {
            display: none;
            font-size: 15px;
        }
        .search-box {
            border: 1px solid #cacaca;
            border-radius: 3px;
            font-size: 15px;
            line-height: 15px;
            margin-left: 6px;
            padding: 3px 8px 5px;
            outline: none;
        }
        .no-entries-message {
            padding: 7px 10px 11px;
            opacity: 0.7;
        }
    }
    .invalid-number-message {
        position: absolute;
        z-index: 1;
        font-size: 13px;
        left: 25px;
        top: -7px;
        background: #fff;
        padding: 0 5px;
        color: #de0000;
    }
    .special-label {
        display: none;
        position: absolute;
        z-index: 1;
        font-size: 13px;
        left: 25px;
        top: -7px;
        background: #fff;
        padding: 0 5px;
        white-space: nowrap;
    }
}
