/* ========================================================================
     Component: dropzone
 ========================================================================== */

.dropzone {
    border: 0;

    .dz-default.dz-message {
        margin: 2em 0;
        font-size: 35px;
    }
    // customized image preview and detials position
    .dz-preview.dz-image-preview {
        .dz-image {
            border-radius: 1px;
        }
        .dz-details {
            position: relative;
            line-height: 1;
            padding: 6px 4px;
            margin: 0;
            opacity: 1;
            font-size: 14px;
        }
    }
    // image hover effect
    .dz-preview .dz-image img {
        transition: transform .2s ease;
    }
    .dz-preview:hover .dz-image img {
        -webkit-filter: none;
        filter: none;
        transform: scale(2);
        transition: transform .5s ease;
    }
    // pre image upload box
    .dz-preview.dz-file-preview .dz-image {
        border-radius: 1px;
        background-image: none;
        background-color: $gray-lighter;
    }
    // hide filename
    .dz-filename {
        display: none;
    }
    // remove link
    .dz-preview .dz-remove {
        color: $text-muted;
        font-size: 12px;
        &:hover {
            color: $danger;
            text-decoration: none;
        }
    }

    .dz-preview .dz-error-message {
        top: 130px;
        border-radius: 2px;
        transition: all .2s ease;
        &:after {
            display: none;
        }
    }
    .dz-preview.dz-error:hover .dz-error-message {
        top: 100px;
    }

    &.dropzone-drag-active {
        box-shadow: 0 0 4px $primary !important;
    }
}
