.org-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "members name nmf";
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
    grid-template-areas: "name"
                         "members"
                         "nmf";

  }

  .org-info-nmf {
    display: flex;
    flex-direction: column;
    grid-area: nmf;
    @include media-breakpoint-up(sm) {
      margin-top: .5rem;
      padding-top: .5rem;
      border-top: 1px solid $border-color;
    }
  }

  .org-info-members {
    display: flex;
    grid-area: members;
    flex-direction: column;
  }

  .org-info-name {
    grid-area: name;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(sm) {
      border-right: 1px solid $border-color;
      border-left: 1px solid $border-color;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: .5rem;
      padding-bottom: .5rem;
      border-bottom: 1px solid $border-color;
    }
  }
}
