.text-member {
  text-align: right;
  @media (max-width: 520px) {
    text-align: left;
  }
}
.member-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 520px) {
    flex-direction: column;
  }
}

.full-width-left {
  width: 100%;
  float: left;
}

.full-width-right {
  width: 100%;
  float: right;
  @media (max-width: 520px) {
    float: left;
  }
}

.vertical-div {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.horizontal-div {
  display: flex;
  flex-direction: row;
}

.full-width-div {
  width: 100%;
}

.full-height {
  height: 100%;
}
.div-flex-end {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}

.scroll-y {
  overflow: scroll;
  overflow-x: hidden;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.vertical-align-center {
  display: flex;
  align-items: center;
}

.div-overflow-hidden {
  overflow: hidden;
}
