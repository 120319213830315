/* ========================================================================
     Component: notesplit
 ========================================================================== */

.notesplit-button {
    display: block;
    padding-left: 5px;
    cursor: pointer;
}

.file-button {
    display: block;
    padding-left: 5px;
    cursor: pointer;
}

.openFile {
    padding-left: 5px;
    cursor: pointer;
}

.notesplit-button-cl {
    margin-left: 20px;
}